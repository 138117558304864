<template>
  <div>
    <b-modal
      v-model="isShowAddProductModal"
      centered
      title="Produk"
      scrollable
      no-close-on-backdrop
      ok-title="Save"
      @ok="productSubmit"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormProduct">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(addProduct)"
          @reset.prevent="productResetForm"
        >
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Produk"
                rules="required"
              >
                <b-form-group label="Produk" label-for="product">
                  <v-select
                    id="product"
                    v-model="product.kd_produk"
                    label="kd_produk"
                    :filterable="true"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Type Kd Produk..."
                    :options="productOptions"
                    :reduce="
                      e => {
                        return e.kd_produk
                      }
                    "
                    :disabled="isEdit"
                    @search="onSearch"
                  >
                    <template #no-options="{ search, searching, loading }">
                      {{
                        search.length === 0
                          ? 'Type To Search.....'
                          : 'No Options Data'
                      }}
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ `${option.kd_produk} - ${option.nama_produk}` }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ `${option.kd_produk}` }}
                      </div>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Kuota/Kuantiti"
                rules="required"
              >
                <b-form-group label="Kuota/Kuantiti" label-for="quota">
                  <b-form-input
                    id="quota"
                    v-model="product.quota"
                    autofocus
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Kuota/Kuantiti"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Harga"
                rules="required"
              >
                <b-form-group label="Harga" label-for="harga">
                  <b-form-input
                    id="harga"
                    v-model="product.price"
                    autofocus
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Harga"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-overlay
      variant="white"
      :show="isLoadingData"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @reset.prevent="resetForm">
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Keterangan"
                  rules="required"
                >
                  <b-form-group label="Keterangan" label-for="keterangan">
                    <b-form-input
                      id="keterangan"
                      v-model="form.ket"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Keterangan"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Time Range"
                  rules="required"
                >
                  <b-form-group label="Time Range" label-for="time_range">
                    <date-picker
                      ref="datePicker"
                      v-model="dateTimeRange"
                      type="datetime"
                      range
                      placeholder="Select datetime range"
                      :state="getValidationState(validationContext)"
                      value-type="YYYY-MM-DD HH:mm:ss"
                      format="YYYY-MM-DD HH:mm:ss"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="Daftar Produk"
                  rules=""
                >
                  <b-form-group>
                    <div
                      class="d-flex justify-content-between align-items-center mb-1"
                    >
                      <h5>Daftar Produk</h5>
                      <div class="d-flex">
                        <b-form-input
                          v-model="search"
                          class="mr-1"
                          placeholder="Type Kd Produk..."
                          clearable
                        />
                        <b-button variant="primary" @click="createProduct"
                          ><feather-icon icon="PlusIcon"
                        /></b-button>
                      </div>
                    </div>
                    <b-table
                      ref="refTable"
                      :items="tempProductList"
                      responsive
                      :fields="productListColumn"
                      primary-key="id"
                      show-empty
                      empty-text="No matching records found"
                      class="position-relative"
                      striped
                      hover
                      sticky-header
                    >
                      <template #cell(actions)="data">
                        <feather-icon
                          icon="Edit2Icon"
                          size="16"
                          class="mr-1"
                          @click="editProduct(data)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="16"
                          class="mr-1"
                          @click="deleteProduct(data)"
                        />
                      </template>
                    </b-table>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Form Actions -->
            <div class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                :disabled="isLoading"
                @click="onSubmit"
              >
                <span>Submit</span>
                <b-spinner v-if="isLoading" small class="ml-1" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="$router.go(-1)"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import 'vue-toastification/dist/index.css'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
  BCard,
  BRow,
  BCol,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import service from '@/components/Table/service'
import { temp } from '@/@core/directives/animations'
import { formatCurrency } from '@/@core/utils/filter'

export default {
  components: {
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    BCard,
    BCol,
    BRow,
    BOverlay,
    BTable,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      productOptions: [],
    }
  },
  methods: {
    onSearch(search, loading) {
      this.filter(search, loading, this)
    },
    filter: _.debounce((search, loading, vm) => {
      loading(true)
      service
        .index({
          url: 'produk',
          query: `sort_by=kd_produk&direction=asc&q=${search}`,
        })
        .then(res => {
          // console.log(res.data.data)
          vm.productOptions = res.data.data
        })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  setup(props, { emit, root }) {
    const datePicker = ref(null)
    const blankFormData = {
      ket: null,
      start: null,
      end: null,
      product_list: [],
    }

    const blankFormProduct = {
      kd_produk: null,
      quota: null,
      price: null,
    }

    const dateTimeRange = ref([])

    const productListColumn = ref([
      {
        label: 'Produk',
        key: 'kd_produk',
      },
      {
        label: 'Kuota/Kuantiti',
        key: 'quota',
      },
      {
        label: 'Harga',
        key: 'price',
        formatter: val => formatCurrency(val),
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isEdit = ref(false)
    const search = ref('')

    const form = ref(JSON.parse(JSON.stringify(blankFormData)))
    const product = ref(JSON.parse(JSON.stringify(blankFormProduct)))
    const resetpageData = () => {
      form.value = JSON.parse(JSON.stringify(blankFormData))
    }
    const tempProductList = ref([])

    const isLoading = ref(false)
    const isLoadingData = ref(false)

    const retrieve = () => {
      isLoadingData.value = true
      service
        .show({ url: 'flash-sale', id: root.$route.params.id })
        .then(res => {
          form.value.product_list.splice(0, form.value.product_list.length)
          console.log(dateTimeRange.value)
          form.value.ket = res.data.ket
          form.value.product_list = res.data.details
          form.value.status = res.data.status
          // dateTimeRange.value[0] = res.data.start
          // dateTimeRange.value[1] = res.data.end
          dateTimeRange.value = [res.data.start, res.data.end]
        })
        .finally(() => {
          isLoadingData.value = false
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData)

    const onSubmit = () => {
      form.value.start = dateTimeRange.value[0]
      form.value.end = dateTimeRange.value[1]
      refFormObserver.value.validate().then(ok => {
        if (
          ok &&
          form.value.start !== null &&
          form.value.end !== null &&
          form.value.product_list.length > 0
        ) {
          isLoading.value = true
          if (root.$route.params.id) {
            form.value.id = root.$route.params.id
            service
              .update({ url: 'flash-sale', data: form.value })
              .then(res => {
                retrieve()
              })
              .catch(err => {
                const element = Vue.component('description', {
                  render: h => serializeErrorMessage(h, err),
                })
                const toast = createToastInterface({
                  timeout: 6000,
                })
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error Edit Data',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    description: element,
                  },
                })
              })
              .finally(() => {
                isLoading.value = false
              })
          } else {
            service
              .store({ url: 'flash-sale', data: form.value })
              .then(res => {
                root.$router.go(-1)
              })
              .catch(err => {
                const element = Vue.component('description', {
                  render: h => serializeErrorMessage(h, err),
                })
                const toast = createToastInterface({
                  timeout: 6000,
                })
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error Add Data',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    description: element,
                  },
                })
              })
              .finally(() => {
                isLoading.value = false
              })
          }
        } else {
          let message = ''
          if (form.value.start === null || form.value.end === null) {
            message = 'Isi Time Range dengan benar'
          } else if (form.value.product_list.length < 1) {
            message = 'Jumlah Item/Produk tidak boleh kosong'
          } else {
            message = 'Silahkan isi keterangan'
          }
          const element = Vue.component('description', {
            render: h => serializeErrorMessage(h, message),
          })
          const toast = createToastInterface({
            timeout: 6000,
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Add Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              description: element,
            },
          })
        }
      })
    }

    const isShowAddProductModal = ref(false)

    onMounted(() => {
      if (root.$route.params.id) {
        retrieve()
      }
    })

    const createProduct = () => {
      isShowAddProductModal.value = true
      isEdit.value = false
      product.value = JSON.parse(JSON.stringify(blankFormProduct))
    }

    const addProduct = () => {
      if (isEdit.value) {
        if (root.$route.params.id) {
          console.log(product.value.id)
          service
            .update({ url: 'flash-sale-detail', data: product.value })
            .then(res => {
              isShowAddProductModal.value = false
              retrieve()
              product.value = JSON.parse(JSON.stringify(blankFormProduct))
            })
            .catch(err => {
              const element = Vue.component('description', {
                render: h => serializeErrorMessage(h, err),
              })
              const toast = createToastInterface({
                timeout: 6000,
              })
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Edit Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  description: element,
                },
              })
            })
        } else {
          form.value.product_list = form.value.product_list.map(e => {
            if (e.kd_produk === product.value.kd_produk) {
              e.kd_produk = product.value.kd_produk
              e.price = product.value.price
              e.quota = product.value.quota
            }
            return e
          })
          isShowAddProductModal.value = false
          product.value = JSON.parse(JSON.stringify(blankFormProduct))
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (root.$route.params.id) {
          product.value.flashsale_id = root.$route.params.id
          service
            .store({ url: 'flash-sale-detail', data: product.value })
            .then(res => {
              isShowAddProductModal.value = false
              retrieve()
              product.value = JSON.parse(JSON.stringify(blankFormProduct))
            })
            .catch(err => {
              const element = Vue.component('description', {
                render: h => serializeErrorMessage(h, err),
              })
              const toast = createToastInterface({
                timeout: 6000,
              })
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Edit Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  description: element,
                },
              })
            })
        } else {
          const a = form.value.product_list.filter(
            e => e.kd_produk === product.value.kd_produk
          )
          if (a.length > 0) {
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, 'Produk Sudah ada'),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          } else {
            form.value.product_list.push(product.value)
            isShowAddProductModal.value = false
            product.value = JSON.parse(JSON.stringify(blankFormProduct))
          }
        }
      }
    }

    const editProduct = data => {
      product.value = JSON.parse(JSON.stringify(data.item))
      isShowAddProductModal.value = true
      isEdit.value = true
    }

    const deleteProduct = data => {
      if (root.$route.params.id) {
        Vue.swal({
          title: 'Are you sure to delete it?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Yes, deleted it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          preConfirm: () =>
            service
              .destroy({ url: 'flash-sale-detail', id: data.item.id })
              .then(res => {
                retrieve()
              })
              .catch(err => {
                const element = Vue.component('description', {
                  render: h => serializeErrorMessage(h, err),
                })
                const toast = createToastInterface({
                  timeout: 6000,
                })
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error Delete Data',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    description: element,
                  },
                })
              }),
        }).then(async result => {
          if (result.value) {
            // flashsaleTable.value.refetchData()
          } else if (result.dismiss === 'cancel') {
            Vue.swal({
              title: 'Cancelled',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      } else {
        form.value.product_list.splice(data.index, 1)
      }
    }

    const refFormProduct = ref(null)

    const productSubmit = evt => {
      evt.preventDefault()
      refFormProduct.value.validate().then(ok => {
        if (ok) {
          addProduct()
        }
      })
    }

    watch(search, val => {
      tempProductList.value = tempProductList.value.filter(
        e => e.kd_produk === val
      )
      if (
        search.value === null ||
        (search.value !== null && search.value.trim().length < 1)
      ) {
        tempProductList.value = JSON.parse(
          JSON.stringify(form.value.product_list)
        )
      } else {
        tempProductList.value = form.value.product_list.filter(e =>
          e.kd_produk.toLowerCase().includes(search.value)
        )
      }
    })

    watch(
      () => form.value.product_list,
      val => {
        // console.log(val)
        tempProductList.value = JSON.parse(JSON.stringify(val))
        console.log(tempProductList.value)
        if (search.value !== null || search.value.trim().length > 0) {
          tempProductList.value = tempProductList.value.filter(e =>
            e.kd_produk.includes(search.value)
          )
        }
      },
      { deep: true }
    )

    return {
      tglLahir: null,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      props,
      isLoading,
      isLoadingData,
      dateTimeRange,
      productListColumn,
      isShowAddProductModal,
      product,
      addProduct,
      editProduct,
      refFormProduct,
      productSubmit,
      createProduct,
      isEdit,
      deleteProduct,
      datePicker,
      search,
      tempProductList,

      form,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
